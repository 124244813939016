import classNames from "classnames";
import { Entry } from "contentful";

import Container from "~/components/common/container";
import RichText from "~/components/common/rich-text";
import Editorials from "~/types/editorials";
import contentfulUtils from "~/utils/contentful-utils";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";

import styles from "./editorial-description.module.scss";

type Props = {
  entry: Entry<unknown>;
};

export default function EditorialDescription(props: Props) {
  if (!isActiveEntry(props.entry)) return null;

  assertEditorialType<Editorials.EditorialDescription>(props.entry, "editorialDescription");

  const inspectorMode = contentfulUtils.useInspectorMode(props.entry);

  const { description, backgroundColor, removeHorizontalMargin, removeVerticalPadding, withoutContainer, anchorId } =
    props.entry.fields;

  const content = (
    <>
      {description ? (
        <div {...inspectorMode?.getProps("description")} className={styles.description}>
          <RichText text={description} />
        </div>
      ) : null}
    </>
  );

  return (
    <div id={anchorId} className={classNames(styles.background, backgroundColor ? styles[backgroundColor] : undefined)}>
      {withoutContainer ? (
        content
      ) : (
        <Container
          className={classNames(
            styles.container,
            removeHorizontalMargin ? styles.removeHorizontalMargin : undefined,
            removeVerticalPadding ? styles.removeVerticalPadding : undefined
          )}
        >
          {content}
        </Container>
      )}
    </div>
  );
}
